/* Dashboard.css - Custom styles for V2 Dashboard */

/* Reset and base styles that only apply within the dashboard */
.v2-dashboard {
    /* Reset to clean base */
    font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
        Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #111827;
    background-color: #ffffff;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Typography */
.v2-dashboard h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.v2-dashboard h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
}

.v2-dashboard h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.v2-dashboard p {
    margin-bottom: 1rem;
}

/* Layout components */
.v2-dashboard .sidebar {
    background-color: #fff;
    border-right: 1px solid #e5e7eb;
    height: 100vh;
    overflow-y: auto;
}

.v2-dashboard .main-content {
    background-color: #ffffff;
    height: 100vh;
    overflow-y: auto;
    padding: 2rem;
}

/* Card components */
.v2-dashboard .card {
    background-color: #fff;
    border-radius: 0.75rem;
    border: 1px solid #d1d5db;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 1.5rem;
}

/* Post list item */
.v2-dashboard .post-list-item {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
    cursor: pointer;
    transition: background-color 0.2s;
}

.v2-dashboard .post-list-item:hover {
    background-color: #f3f4f6;
}

.v2-dashboard .post-list-item.active {
    background-color: #eef2ff;
    border-left: 3px solid #4f46e5;
}

/* Form elements */
.v2-dashboard .text-area {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    transition: border-color 0.15s ease-in-out;
    min-height: 100px;
    resize: vertical;
}

.v2-dashboard .text-area:focus {
    outline: none;
    border-color: #6366f1;
    box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
}

/* Buttons */
.v2-dashboard .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 0.375rem;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
}

.v2-dashboard .btn-primary {
    background-color: #4f46e5;
    color: white;
}

.v2-dashboard .btn-primary:hover {
    background-color: #4338ca;
}

.v2-dashboard .btn-secondary {
    background-color: white;
    color: #111827;
    border: 1px solid #d1d5db;
}

.v2-dashboard .btn-secondary:hover {
    background-color: #f3f4f6;
}

/* Collapsible sections */
.v2-dashboard .collapsible-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
    cursor: pointer;
    user-select: none;
}

/* Feedback UI */
.v2-dashboard .feedback-buttons {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
}

.v2-dashboard .thumbs-button {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    background-color: #f3f4f6;
}

.v2-dashboard .thumbs-button:hover {
    background-color: #e5e7eb;
}

.v2-dashboard .thumbs-button.active-positive {
    background-color: #dcfce7;
    color: #166534;
}

.v2-dashboard .thumbs-button.active-negative {
    background-color: #fee2e2;
    color: #b91c1c;
}

/* Animations */
.v2-dashboard .spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Research and Transcript components */
.v2-dashboard .research-item,
.v2-dashboard .transcript-item {
    background-color: #f9fafb;
    border-radius: 0.375rem;
    padding: 1rem;
    margin-bottom: 1rem;
}

.v2-dashboard .research-title {
    color: #4f46e5;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

/* Copy button */
.v2-dashboard .copy-button {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    background-color: #f3f4f6;
    color: #6b7280;
    font-size: 0.75rem;
    transition: all 0.15s ease-in-out;
}

.v2-dashboard .copy-button:hover {
    background-color: #e5e7eb;
}

/* Scrollbar styling */
.v2-dashboard ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.v2-dashboard ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.v2-dashboard ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
}

.v2-dashboard ::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1;
}
